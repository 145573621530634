<template>
  <section class="p-4 mt-4 border-top">
    <div class="container">
      <h1>All Sets</h1>
      <div class="row pt-3">
        <div
          class="col-lg-3 col-md-3 mb-4"
          v-for="set in getSets"
          v-bind:key="set._id"
        >
          <SetCard :set="set" />
        </div>
        <PaginationNav />
      </div>
    </div>
  </section>
</template>

<script>
import SetCard from "@/components/SetCard.vue";
import PaginationNav from "@/components/PaginationNav.vue";

export default {
  name: "SetsView",
  components: {
    SetCard,
    PaginationNav,
  },
  mounted() {
    document.title = "Square Anatomy - Sets";
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.$store.commit("setFilter", '{"_state": true}');
    this.$store.dispatch("fetchSets");
  },
  computed: {
    getSets() {
      return this.$store.getters.getSets;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
