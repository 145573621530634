<template>
  <section class="p-4 mt-4 bg-light border-top">
    <div class="container">
      <div class="row pt-3">
        <div class="offset-lg-3 col-lg-6 col-md-6 col-sm-12">
          <h1>Contact</h1>
          <div v-if="send">
            <div class="alert alert-secondary" role="alert">
              <div v-html="contact.contactform_message"></div>
            </div>
          </div>
          <div v-if="!send">
            <input
              type="text"
              class="form-control form-control-lg mb-2"
              placeholder="Your name"
              aria-label="Your name"
              aria-describedby="button-addon2"
              v-model="name"
            />
            <input
              type="email"
              class="form-control form-control-lg mb-2"
              placeholder="Your email"
              aria-label="Your email"
              aria-describedby="button-addon2"
              v-model="email"
            />
            <textarea
              class="form-control form-control-lg mb-2"
              id="exampleFormControlTextarea1"
              placeholder="Your message"
              aria-label="Your message"
              rows="3"
              v-model="message"
            ></textarea>
            <button
              :class="ValidateEmail ? '' : 'disabled'"
              class="btn btn-secondary w-100 mb-3"
              type="button"
              id="button-addon2"
              @click="sendContactForm()"
            >
              Send
            </button>
          </div>
          <div class="mt-4" v-html="contact.address"></div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-12">
          <div class="d-grid gap-2">
            <a
              v-for="button in contact.contact_links"
              v-bind:key="button"
              type="button"
              class="btn text-white"
              :class="button.color"
              :href="button.url"
              target="button.target"
            >
              <i class="bi" :class="button.icon"></i>
              {{ button.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactView",
  data: function () {
    return {
      name: "",
      email: "",
      message: "",
      contact: {},
      send: false,
    };
  },
  computed: {
    ValidateEmail() {
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email) &&
        this.name &&
        this.message
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    document.title = "Square Anatomy - Contact";
  },
  created() {
    this.fetchContact();
  },
  methods: {
    fetchContact() {
      axios
        .get("https://db.squareanatomy.com/api/content/item/contact", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.contact = response.data;
        })
        .catch((err) => console.log(err));
    },
    sendContactForm() {
      // let requestOptions = JSON.stringify({ form: { email: this.email } });
      axios
        .post(
          "https://db.squareanatomy.com/api/inbox/submit/0a20f1771ce6898d96b459cdc5cef9699da809a9",
          {
            data: {
              name: this.name,
              email: this.email,
              message: this.message,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
            },
          }
        )
        .then(() => {
          // console.log(response);
          this.send = true;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
