<template lang="html">
  <div class="card set-card h-100">
    <router-link :to="{ name: 'set', params: { slug: set.slug } }">
      <div class="image-container">
        <img
          :src="
            'https://db.squareanatomy.com/storage/uploads/' +
            getRandomImage(set.images).path +
            '?modified=' +
            set._modified
          "
        />
      </div>
      <div class="card-body">
        <h5 class="card-title">{{ set.name }}</h5>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SetCard",
  props: {
    set: Object,
  },
  methods: {
    getRandomImage(images) {
      return images[Math.floor(Math.random() * images.length)];
    },
  },
};
</script>

<style lang="css" scoped>
.card-title {
  /*color: #c4007c;*/
}
.set-card {
  border: none;
  background-color: transparent;
}
.set-card img {
  border-radius: 15px;
  width: 100%;
  -webkit-filter: drop-shadow(0px 2px 10px #555);
  filter: drop-shadow(0px 2px 10px #555);
}
.set-card .card-body {
  padding: 5px;
}
.image-container {
  padding: 40px;
  background-color: #f5f5f5;
  /* position: relative; */

  /*padding-top: 75%;  4:3 aspect ratio */
  /* background-repeat: no-repeat;
  background-size: cover;*/
  text-align: center;
  background-position: center;
}

.image-container img {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;*/
  object-position: center;
}
</style>
