<template lang="html">
  <div class="accordion mb-3 mt-3" id="accordion" v-if="!loading">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingPackInfo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#packInfo"
          aria-expanded="true"
          aria-controls="packInfo"
        >
          {{ packinfo.title }}
        </button>
      </h2>
      <div
        id="packInfo"
        class="accordion-collapse collapse"
        aria-labelledby="headingPackInfo"
        data-bs-parent="#accordion"
      >
        <div class="accordion-body" v-html="packinfo.description"></div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingRarityInfo">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#rarityInfo"
          aria-expanded="true"
          aria-controls="rarityInfo"
        >
          {{ rarityinfo.title }}
        </button>
      </h2>
      <div
        id="rarityInfo"
        class="accordion-collapse collapse"
        aria-labelledby="headingRarityInfo"
        data-bs-parent="#accordion"
      >
        <div class="accordion-body" v-html="rarityinfo.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProductInfo",
  data: function () {
    return {
      loading: true,
      packinfo: "",
      rarityinfo: "",
    };
  },
  created() {
    this.fetchPackInfo();
    this.fetchRarityInfo();
  },
  methods: {
    fetchPackInfo() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.squareanatomy.com/api/content/item/packinfo", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.packinfo = response.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    fetchRarityInfo() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.squareanatomy.com/api/content/item/rarityinfo", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.rarityinfo = response.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="css" scoped></style>
