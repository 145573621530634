<template lang="html">
  <div class="border-top mt-4"></div>
  <footer class="container pt-4" v-if="footer">
    <div class="row">
      <div class="col-sm-12 col-md-3 mb-2">
        <div v-html="footer.column_1"></div>
        <small class="mb-3 text-muted align-middle">
          <span v-html="footer.legal_notice.copyright"></span>
          2021–{{ getYear }}
        </small>
      </div>
      <div class="col-sm-12 col-md-3 mb-2">
        <div v-html="footer.column_2"></div>
      </div>
      <div class="col-sm-12 col-md-3 mb-2">
        <div v-html="footer.column_3"></div>
      </div>
      <div class="col-sm-12 col-md-3 mb-2">
        <div v-html="footer.column_4"></div>
        <div class="d-grid gap-2">
          <a
            v-for="social in footer.social_links"
            v-bind:key="social"
            type="button"
            class="btn btn-secondary btn-sm text-white"
            :href="social.url"
            target="_blank"
          >
            <i class="bi bi-instagram" v-if="social.type == 'instagram'"></i>
            <i class="bi bi-twitter" v-if="social.type == 'twitter'"></i>
            {{ social.name }}
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";

export default {
  name: "FooterSection",
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
  data: function () {
    return {
      loading: true,
      footer: false,
    };
  },
  created() {
    this.fetchFooterText();
  },
  methods: {
    fetchFooterText() {
      axios
        .get("https://db.squareanatomy.com/api/content/item/footer", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.footer = response.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="css" scoped></style>
