<template>
  <div class="home">
    <!-- <HomeFeatured /> -->
    <SetSection />
    <!-- <NewsletterSection /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HomeFeatured from "@/components/HomeFeatured.vue";
import SetSection from "@/components/SetSection.vue";
// import NewsletterSection from "@/components/NewsletterSection.vue";

export default {
  name: "HomeView",
  mounted() {
    document.title = "Square Anatomy";
  },
  components: {
    // HomeFeatured,
    SetSection,
    // NewsletterSection,
  },
};
</script>
