<template>
  <section class="pt-4 mt-4 border-top">
    <div class="container">
      <div class="row pt-3">
        <div
          class="col-lg-3 col-md-4 mb-2"
          v-for="set in getSets"
          v-bind:key="set._id"
        >
          <SetCard :set="set" />
        </div>
      </div>
      <router-link class="" :to="{ name: 'sets' }">
        <div class="d-grid">
          <button type="button" class="btn btn-secondary btn-lg text-white">
            View More Sets
          </button>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
import SetCard from "@/components/SetCard.vue";

export default {
  name: "SetSection",
  components: {
    SetCard,
  },
  data: function () {
    return {};
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.$store.commit("setFilter", '{"featured": true, "_state": true}');
    this.$store.dispatch("fetchSets");
  },
  computed: {
    getSets() {
      return this.$store.getters.getSets;
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
