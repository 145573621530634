<template>
  <section class="pt-4 mt-4 border-top">
    <div class="container" v-if="typeof set.name != 'undefined'">
      <div class="row pt-3">
        <div class="col-lg-8 col-md-12">
          <CardsSection :set="set" />
          <!-- Dit is het linker gedeelte -->
          <!-- <div class="row">
            <div
              class="col-lg-3 col-lg-4 col-md-6 mb-4"
              v-for="image in shuffleArray(set.images)"
              v-bind:key="image._id"
            >
              <div class="image-container">
                <img
                  :src="
                    'https://db.squareanatomy.com/storage/uploads/' +
                    image.path +
                    '?modified=' +
                    set._modified
                  "
                />
              </div>
            </div>
          </div> -->

          <!-- Content -->
          <div class="row border-top pt-3" v-if="set.content.length > 0">
            <div
              class="col-lg-12 col-md-12"
              v-for="content in set.content"
              v-html="content"
              v-bind:key="content"
            ></div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 mb-5 mt-1">
          <!-- Dit is het rechter gedeelte (1/3) -->
          <!-- Hier komt de inhoud van het rechter gedeelte -->
          <h1>{{ set.name }}</h1>
          <h3>{{ set.sub }}</h3>
          <CallToAction />
          <ProductInfo />
          <!-- <div class="d-grid gap-2 pb-4" v-if="hasSetButtons">
            <a
              v-for="button in set.buttons"
              v-bind:key="button"
              type="button"
              class="btn btn-lg text-white"
              :class="button.color"
              :href="button.url"
              :target="button.target"
            >
              <i class="bi" :class="button.icon"></i>
              {{ button.name }}
            </a>
          </div> -->

          <h4>Tags</h4>
          <router-link
            v-for="(tag, index) in set.tags"
            v-bind:key="tag"
            :to="{ name: 'tag', params: { tag: tag } }"
            class="text-secondary"
          >
            {{ tag }}<span v-if="index < set.tags.length - 1">, </span>
          </router-link>
          <!-- <ShareButton :set="set" class="pt-3" /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
// import ShareButton from "@/components/ShareButton.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import CardsSection from "@/components/CardsSection.vue";
import CallToAction from "@/components/CallToAction.vue";

export default {
  name: "SetView",
  components: {
    // ShareButton,
    ProductInfo,
    CardsSection,
    CallToAction,
  },
  computed: {
    hasSetButtons() {
      if (this.set.buttons) {
        if (this.set.buttons.length > 0) {
          return true;
        }
      }
      return false;
    },
  },
  data: function () {
    return {
      loading: true,
      set: {},
    };
  },
  created() {
    this.fetchSet();
    this.fetchPackInfo();
    this.fetchRarityInfo();
  },
  methods: {
    shuffleArray(array) {
      const shuffled = array.slice(); // Create a copy of the original array
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    },
    fetchSet() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.squareanatomy.com/api/content/item/sets", {
          params: {
            limit: 1,
            filter: '{"slug": "' + this.$route.params.slug + '",}',
          },
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.set = response.data;
          document.title = "Square Anatomy - " + this.set.name;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    fetchPackInfo() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.squareanatomy.com/api/content/item/packinfo", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.packinfo = response.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
    fetchRarityInfo() {
      // console.log(this.$route.params.slug);
      axios
        .get("https://db.squareanatomy.com/api/content/item/rarityinfo", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.rarityinfo = response.data;
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  beforeRouteLeave(to, from, next) {
    const backdrop = document.querySelector(".modal-backdrop");
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }
    next();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.show-zoom {
  cursor: zoom-in;
}

img {
  border-radius: 15px;
  width: 100%;
  -webkit-filter: drop-shadow(0px 2px 10px #555);
  filter: drop-shadow(0px 2px 10px #555);
}

.image-container {
  padding: 40px;
  background-color: #f5f5f5;
  /* position: relative; */

  /*padding-top: 75%;  4:3 aspect ratio */
  /* background-repeat: no-repeat;
  background-size: cover;*/
  text-align: center;
  background-position: center;
}

.image-container img {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;*/
  object-position: center;
}
</style>
