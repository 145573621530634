<template>
  <section class="p-4 mt-4 bg-light border-top">
    <div class="container" v-if="tag">
      <h1>
        Sets <small>({{ tag }})</small>
      </h1>
      <div class="row pt-3">
        <div
          class="col-lg-3 col-md-3 mb-4"
          v-for="set in getSets"
          v-bind:key="set._id"
        >
          <SetCard :set="set" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SetCard from "@/components/SetCard.vue";

export default {
  name: "SetsView",
  components: {
    SetCard,
  },
  data: function () {
    return {
      tag: null,
    };
  },
  created() {
    this.$store.dispatch("resetPagination");
    this.fetchSets();
  },
  computed: {
    getSets() {
      return this.$store.getters.getSets;
    },
  },
  methods: {
    fetchSets() {
      this.tag = this.$route.params.tag;
      this.$store.commit(
        "setFilter",
        '{"tags": "' + this.tag + '", "_state": true}'
      );
      document.title = "Square Anatomy - Sets (" + this.tag + ")";
      this.$store.dispatch("fetchSets");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
