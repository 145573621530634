<template lang="html">
  <div class="mt-4">
    <h4>Buy a Collector Pack!</h4>
    <h3 class="text-success mt-4 mb-4">€ 12,95</h3>
    <div class="d-grid">
      <button
        type="button"
        class="btn btn-secondary btn-lg text-white rounded-0"
        @click="buyNow()"
      >
        Buy now
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallToAction",
  methods: {
    buyNow() {
      window.open(
        "https://squareanatomy.myshopify.com/cart/" + 48905242149190 + ":1"
      );
    },
  },
};
</script>

<style lang="css" scoped></style>
