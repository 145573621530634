<template>
  <nav class="navbar navbar-transparent navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <img class="navbar-logo" alt="Vue logo" src="../assets/logo.png" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link active" aria-current="page" to="/">
              <span class="gradient-text">Square Anatomy</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/sets">Sets</router-link>
          </li>
          <div v-if="tags.length > 0">
            <li class="nav-item" v-for="tag in tags" v-bind:key="tag">
              <router-link
                class="nav-link"
                :to="{ name: 'tag', params: { tag: tag.toLowerCase() } }"
              >
                {{ tag }}
              </router-link>
            </li>
          </div>
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider" /></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li> -->
        </ul>
        <!-- <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link"
              target="_blank"
              href="https://www.etsy.com/shop/SephenDeVos"
            >
              Shop
            </a>
          </li>
        </ul> -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link me-2" to="/about">About</router-link>
          </li>
        </ul>
        <form class="d-flex">
          <router-link class="nav-link me-2" to="/contact">
            <button class="btn btn-outline-primary" type="submit">
              Contact
            </button>
          </router-link>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: "NavBar",
  data: function () {
    return {
      loading: true,
      tags: [],
    };
  },
  created() {
    this.fetchCategoryTags();
  },
  methods: {
    fetchCategoryTags() {
      this.loading = true;
      axios
        .get("https://db.squareanatomy.com/api/content/item/categorytags", {
          params: {},
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.tags = response.data.tags;
          if (response.data.tags == null) {
            this.tags = [];
          }
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  position: relative;
  top: 10px;
}
.navbar-brand {
  position: absolute;
  top: -16px;
  left: calc(50% + 15px);
  margin-left: -50px !important;
  display: block;
  z-index: 1000;
}
.navbar-logo {
  -webkit-filter: drop-shadow(0px 2px 10px #555);
  filter: drop-shadow(0px 2px 10px #555);
}
.navbar-transparent {
  background: transparent !important;
}
</style>
