<template>
  <div class="row pt-3">
    <div
      class="col-lg-4 col-md-6 mb-2"
      v-for="card in cards"
      v-bind:key="card._id"
    >
      <CardCard :card="card" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CardCard from "@/components/CardCard.vue";

export default {
  name: "CardsSection",
  props: {
    set: Object,
  },
  components: {
    CardCard,
  },
  data: function () {
    return {
      cards: [],
    };
  },
  created() {
    // this.fetchSetCards();
  },
  computed: {
    getSets() {
      return this.$store.getters.getSets;
    },
  },
  methods: {
    fetchSetCards() {
      this.loading = true;
      axios
        .get("https://db.squareanatomy.com/api/content/items/cards", {
          params: {
            filter: '{"set._id": "' + this.set._id + '",}',
          },
          headers: {
            "api-key": "API-8fe9886aa07d0fd6fe2ed2138b650073b910f84a",
          },
        })
        .then((response) => {
          // console.log(response);
          this.cards = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false; // Make sure to handle errors and set loading state accordingly
        });
    },
  },
  watch: {
    set: {
      immediate: true,
      handler(newSet) {
        if (newSet && newSet._id) {
          // console.log(newSet._id);
          this.fetchSetCards();
        }
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-img-top {
  border-radius: 3px;
}
</style>
