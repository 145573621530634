<template lang="html">
  <div class="card set-card h-100">
    <div class="image-container">
      <img
        :src="
          'https://db.squareanatomy.com/storage/uploads/' +
          card.image[0].path +
          '?modified=' +
          card._modified
        "
      />
    </div>
    <div class="card-body">
      <h6 class="card-title">
        {{ card.name }}
        <small> - {{ card.rarity }}</small>
      </h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardCard",
  props: {
    card: Object,
  },
  methods: {},
};
</script>

<style lang="css" scoped>
.card-title {
  /*color: #c4007c;*/
}
.set-card {
  border: none;
  background-color: transparent;
}
.set-card img {
  border-radius: 2vh;
  width: 100%;
  -webkit-filter: drop-shadow(0px 2px 10px #555);
  filter: drop-shadow(0px 2px 10px #555);
}
.set-card .card-body {
  padding: 5px;
}
.image-container {
  padding: 40px;
  background-color: #f5f5f5;
  /* position: relative; */

  /*padding-top: 75%;  4:3 aspect ratio */
  /* background-repeat: no-repeat;
  background-size: cover;*/
  text-align: center;
  background-position: center;
}

.image-container img {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;*/
  object-position: center;
}
</style>
